import Settings from "@mui/icons-material/Settings"
import { Grid, Typography } from "@mui/material"
import * as React from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { pagesUrl } from "../../core/appConstants"
import { AppNavBarContext } from "../../core/context/nav-bar/AppNavBarContext"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { AppSideBarContext } from "../../core/context/side-bar/side-bar-context"
import { formatToFrench } from "../../core/services/date-service"
import { OfferCard } from "./OfferCard"

export function MyOfferPage(): React.JSX.Element {
  const location = useLocation()

  const { organization } = React.useContext(OrganizationContext)
  const { setShowAppSide } = React.useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setBreadcrumbSteps, setIcon } = React.useContext(AppNavBarContext)

  useEffect(() => {
    setShowAppSide(true)
    setTitle("Mon offre")
    setIcon(<Settings />)
    setBreadcrumbSteps([{ label: "Mon offre" }])
    setPreviousUrl(pagesUrl.HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setIcon, setBreadcrumbSteps])

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      alignItems="flex-start"
      borderRadius={3}
      gap={3}
      p={3}
      sx={{ backgroundColor: "white" }}>
      <Grid item xs={12}>
        <Typography fontSize={24} fontWeight={600}>
          Inclus dans votre offre
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" gap={1}>
        {organization?.createdDate && (
          <>
            <Typography variant="body2">Depuis le</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {formatToFrench(new Date(organization?.createdDate))}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Votre abonnement vous permet d’accéder aux services Time To Beem :</Typography>
      </Grid>
      <Grid container>
        <OfferCard
          label="Projet Beem Shot"
          maxLicense={organization?.bsMaxProjectCount}
          countLicense={organization?.bsProjectCounter}
          isOptionActive={organization?.beemShotOption}
        />

        <OfferCard
          label="Projet Beem Master"
          maxLicense={organization?.maxProjectCount}
          countLicense={organization?.projectCounter}
          isOptionActive={organization?.beemMasterOption}
        />

        <OfferCard
          label="Projet Beem Pilot"
          maxLicense={organization?.maxRseeDocumentCount}
          countLicense={organization?.rseeDocumentCounter}
          isOptionActive={organization?.beemPilotOption}
        />

        <OfferCard
          label="Nombre de maquette autorisé"
          maxLicense={organization?.bsMaxBimModelCount}
          countLicense={organization?.bsBimModelCounter}
          isOptionActive
        />
      </Grid>

      <Grid container>
        <OfferCard
          label="Collaborateurs"
          maxLicense={organization?.maxLicenceCount}
          countLicense={organization?.licenceCounter}
          isOptionActive
          backgroundColor="#0D6E58"
        />

        <OfferCard
          label="Collaborateurs"
          maxLicense={organization?.maxInvitedCount}
          countLicense={organization?.invitedCounter}
          isOptionActive={organization?.beemMasterOption}
          backgroundColor="#374771"
        />

        <Grid item xs={3.9} />
      </Grid>
    </Grid>
  )
}
